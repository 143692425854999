@import '../../mixins';

.front-banner {
  &__swiper {
    width: 100%;
    height: auto;

    border-radius: var(--radius-common);

    @include mediaTablet {
      position: relative;
      left: calc(var(--container-offset) * -1);

      width: calc(100% + (var(--container-offset) * 2));

      border-radius: 0;
    }
  }

  &__navigation {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    margin: auto;
    padding: 0 rem(30);

    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    pointer-events: none;

    @include mediaBigDesktop {
      padding: 0 big(30);
    }

    @include media(#{rem(1000)}) {
      display: none;
    }

    & .button {
      pointer-events: all;

      transition: opacity var(--animation-timing) var(--cubic-bezier),
        visibility var(--animation-timing) var(--cubic-bezier);

      &:disabled {
        pointer-events: none;

        opacity: 0;
        visibility: hidden;
      }
    }
  }

  &__image {
    width: 100%;
    height: auto;
    display: flex;

    border-radius: var(--radius-common);
    overflow: hidden;

    & picture {
      height: auto;
      display: flex;
    }

    & img,
    & picture {
      width: 100%;
      height: auto;

      pointer-events: none;
      user-select: none;
    }
  }

  &__pagination {
    margin-top: rem(20);

    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: rem(5);

    @include mediaBigDesktop {
      margin-top: big(20);

      gap: big(5);
    }

    @include media(#{rem(1000)}) {
      margin-top: rem(10);
    }
  }

  &__bullet {
    display: flex;

    width: rem(10);
    height: rem(3);

    background-color: var(--elements-gray-secondary);
    border-radius: rem(1600);

    transition: background-color var(--animation-timing) var(--cubic-bezier),
      width var(--animation-timing) var(--cubic-bezier);

    cursor: pointer;
    user-select: none;

    @include mediaBigDesktop {
      width: big(10);
      height: big(3);

      border-radius: big(1600);
    }

    &_active {
      width: rem(25);

      background-color: var(--accent-color);

      cursor: auto;

      @include mediaBigDesktop {
        width: big(25);
      }
    }
  }
}
